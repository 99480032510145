<template>
  <b-container class="p-0" fluid='lg'>
    <h3>Regions</h3>
    <p>
      Create assets and regions and share with your members
      <b-icon v-if="isLoading" icon="arrow-clockwise" animation="spin"></b-icon>
      <span class="text-danger">{{ error }}</span>
    </p>
    <div class="text-muted d-flex justify-content-between">
      <div>
        <b-link @click="clearTag()"
          ><span class="mr-2"> <b-icon-map-fill /> all </span></b-link
        >
        <span class="mr-3" v-for="t in tags" :key="t._id">
          <b-link @click="setTag(t.tag)" class="text-lowercase">{{
            t.tag
          }}</b-link>
        </span>
      </div>
    </div>
    <br />
    <div class="d-flex">
      <b-button class="ml-auto" size="sm" @click="regionForm=!regionForm">
        <b-icon-plus />New Region</b-button>
    </div>
    <br />
    <b-row>
      <b-col
        cols="12"
        md="12"
        class="pb-4"
        v-for="region in regions"
        :key="region._id"
      >
        <b-card class="h-100" no-body>
          <b-card-header
            class="d-flex justify-content-between align-items-start p-2"
          >
            <div>
              <span class="mr-2">
                <b-icon-file-bar-graph-fill /> {{ region.ui }}
              </span>
              <span class="mr-2">
                <b-icon-person-badge /> {{ region.admin.length }}</span
              >
              <span class="mr-2">
                <b-icon-person /> {{ region.member.length }}</span
              >
            </div>
            <div>
              <span class="mr-2" v-if="region.tags.length">
                <b-icon-tags />
              </span>
              <span class="mr-2" v-for="t in region.tags" :key="t._id">
                <b-link @click="setTag(t)" class="text-lowercase">{{
                  t
                }}</b-link>
              </span>
            </div>
          </b-card-header>
      
          <region-info :info="region.info" />

          <b-card-footer class="d-flex p-2 bg-white">
            <b-button
              :to="{ name: 'Region Detail', params:{id: region._id }}"
              class="ml-auto"
              size="sm"
              variant=""
            >
              
              <span v-if="isAdmin(region)" class="mr-2"
                ><b-icon-pencil scale="0.8" />Admin</span
              >
              <span v-if="isMember(region)" class="mr-2"
                ><b-icon-pencil scale="0.8" />Member</span
              >
              <span v-if="region.isPublic" class="mr-2"
                ><b-icon-unlock scale="0.8" /> Public</span
              >
            </b-button>
          </b-card-footer>
        </b-card>
      </b-col>
    </b-row>

    <b-modal
      v-model="regionForm"
      centered
      button-size="sm"
      @ok="addRegion"
      @cancel="clearRegionForm"
      @close="clearRegionForm"
    >
      <template #modal-title> New Region</template>
      <b-form>
        <b-form-group label="city">
          <b-input v-model="regionFormData.info.city"></b-input>
        </b-form-group>
        <b-form-group label="state">
          <b-input v-model="regionFormData.info.state"></b-input>
        </b-form-group>
        <b-form-group label="description">
          <b-textarea v-model="regionFormData.info.description"></b-textarea>
        </b-form-group>
        <b-form-group label="tags">
          <b-form-tags v-model="regionFormData.tags"></b-form-tags>
        </b-form-group>
        <b-form-group>
          <b-checkbox v-model="regionFormData.isPublic"> Public</b-checkbox>
        </b-form-group>
      </b-form>
      <template #modal-fooer="{cancel, ok}">
        <b-button @click="cancle">Cancel</b-button>
        <b-utton @click="ok">Submit</b-utton>
      </template>
    </b-modal>
  </b-container>
</template>

<script>
const axios = require("axios");
import { mapGetters } from "vuex";
import RegionInfo from "../../components/RegionInfo.vue";
export default {
  components: { RegionInfo },
  data: function() {
    return {
      isLoading: false,
      error: "",
      regions: [],
      regionsData: [],
      regionForm: false,
      regionFormData: {
        ui: "region",
        info: {
          city: "",
          state: "",
          description: ""
        },
        tags: [],
        isPublic: true,
      },
      tags: []
    };
  },
  computed: {
    ...mapGetters("user", {
      isLoggedIn: "getUserStatus",
      user: "getUser"
    })
  },
  methods: {
    addRegion: function() {
      this.isLoading = true;
      console.log("add region");
      axios
        .post("/.netlify/functions/region/", this.regionFormData)
        .then(res => {
          console.log(res.data);
          this.regions.push(res.data);
        })
        .catch(err => {
          this.error = err;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    clearRegionForm: function() {
      console.log("clear region form");
      this.regionForm = false;
      this.regionFormData = {
        ui: "region",
        info: {
          city: "",
          staet: "",
          description: ""
        },
        tags: []
      };
    },
    isAdmin: function(region) {
      if (!this.user || !this.user.db_token) return false;
      for (let admin of region.admin) {
        if (admin == this.user.db_token) return true;
      }
      return false;
    },
    isMember: function(region) {
      if (!this.user || !this.user.db_token) return false;
      for (let member of region.member) {
        if (member == this.user.db_token) return true;
      }
      return false;
    },
    setTag: function(tag) {
      this.regions = this.regionsData.filter(r => {
        return String(r.tags).indexOf(tag) != -1;
      });
    },
    clearTag: function() {
      this.regions = this.regionsData;
    }
  },
  created: async function() {
    this.isLoading = true;
    axios
      .get("/.netlify/functions/region/")
      .then(res => {
        this.regions = res.data;
        this.regionsData = res.data;
        return axios.get("/.netlify/functions/getTags/");
      })
      .then(res => {
        this.tags = res.data;
      })
      .catch(err => {
        this.error = err;
      })
      .finally(() => {
        this.isLoading = false;
      });
  }
};
</script>
